@import url('https://fonts.cdnfonts.com/css/ds-sofachrome');

.logo {
    font-family: 'DS Sofachrome', sans-serif;
    font-style: italic;
    font-weight: 700;
    color: silver;
  }
  .header__content {
    align-items: center;
    justify-content: space-between;
    min-height: 5em;
    max-width: 110em;
    margin: 0 auto;
  }
  .nav__list {
    column-gap: 20px;
    display: flex;
  }

  .nav__link {
    color: silver;
    transition: all 0.4s;
    position: relative;
    font-size: 1.4em;
  }
  
  .nav__link:hover {
    color: white;
  }
  
  .nav__link::after {
    content: '';
    height: 0.20em;
    width: 100%;
    background-color: #00df9a;
    position: absolute;
    left: 0;
    bottom: -1em;
    transition: all 0.2s;
    opacity: 0;
    pointer-events: none;
  }
  
  .nav__link:hover::after {
    opacity: 1;
  }

  @media (max-width: 1390px) {
        .logo {
        font-size: 1em;
    }
  }

  @media (max-width: 907px) {
    .logo {
      font-size: 0;
    }
    .logo1 {
      width: 25em;
      padding-left: 2em;
    }
  }
  @media (max-width: 770px) {
    .logo1 {
      width:9em;
      padding-left: 2em;
    }

  }

  @media (max-width: 500px) {
    .logo1 {
      width:10em;
      padding-left: 2em;
    }


  }

  @media (max-width: 400x) {
    .logo1 {
      width:2em;
      padding-left: 2em;
    }
  }